import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/Seo";
import Copyright from "../components/Copyright";
import { StaticButton } from "../components/ButtonsCollection";
import { StaticLink } from "../components/LinksCollection";

const ContentBackground = styled.div`
  display: flex;
  background-color: var(--blue);
`;

const ContentWrapper = styled.div`
  --font-size: 65vw;

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  @media (min-width: 1440px) {
    --font-size: 44rem;
  }

  height: calc(var(--font-size) / 2);
  transform: translateY(calc(var(--font-size) / 2 * -1));
`;

const Title = styled.div`
  font-family: GTPressuraProBold;
  font-size: var(--font-size);
  line-height: var(--font-size);
  color: var(--blue);
  text-shadow: 2px 0 0 var(--white), -2px 0 0 var(--white), 0 2px 0 var(--white),
    0 -2px 0 var(--white), 1px 1px var(--white), -1px -1px 0 var(--white),
    1px -1px 0 var(--white), -1px 1px 0 var(--white);
  margin: 0;
  height: var(--font-size);
`;

const TitleSolid = styled(Title)`
  transform: translateY(calc(var(--font-size) * -1));
  color: var(--white);
  width: 50%;
  overflow: hidden;
`;

const Text = styled.p`
  line-height: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  text-align: center;

  @media (max-width: 375px) {
    margin-top: calc(var(--font-size) / 2 + 3rem);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 3rem -0.75rem;
`;

const Button = styled(StaticButton)`
  background: transparent;
  color: var(--white);
  border-color: var(--white);
  margin: 0.75rem;
`;

const PhoneLink = styled(StaticLink)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  height: 0.875rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Похоже, эту страницу ещё не привезли" />
    <main>
      <ContentBackground>
        <ContentWrapper className="container">
          <TitleWrapper>
            <Title>404</Title>
            <TitleSolid>404</TitleSolid>
          </TitleWrapper>
          <Text>Похоже, эту страницу ещё не привезли</Text>
          <ButtonWrapper>
            <Link to="/clients/">
              <Button>заказать перевозку</Button>
            </Link>
            <Link to="/shippings/">
              <Button>взять груз</Button>
            </Link>
          </ButtonWrapper>
          <Footer>
            <Copyright />
            <a href="tel:78007754502">
              <PhoneLink>+7 (800) 775 45 02</PhoneLink>
            </a>
          </Footer>
        </ContentWrapper>
      </ContentBackground>
    </main>
  </Layout>
);

export default NotFoundPage;
